import { useState, useEffect } from 'react';

import { window } from '@constants';

export const useWindowSize = () => {
  function getSize() {
    return {
      width: window?.innerWidth,
      height: window?.innerHeight
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useBreakpoints = () => {
  const { width } = useWindowSize();
  const [windowType, setWindowType] = useState({
    isSm: undefined,
    isMd: undefined,
    isLg: undefined
  });

  useEffect(() => {
    setWindowType({
      isSm: width < 640,
      isMd: width < 1025 && width >= 640,
      isLg: width >= 1025
    });
  }, [width]);

  return windowType;
};
