import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Logo from '@images/logo.svg';

import { ContactModal } from '@components/ContactModal';

import { COLORS } from '@constants';

import styles from './style.module.scss';

export const Header = ({ colorSetting: { background }, resetPages }) => {
  return (
    <header className={styles.root} style={{ background }}>
      <div className={styles.container}>
        <Link to="/" onClick={resetPages}>
          <img className={styles.image} alt="j-design" src={Logo} />
        </Link>
        <div className={styles.rightWrapper}>
          <div className={styles.btnWrapper}>
            <ContactModal withIcon />
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  colorSetting: PropTypes.shape({
    textColor: PropTypes.string,
    iconColor: PropTypes.string,
    background: PropTypes.string
  }),
  resetPages: PropTypes.func
};

Header.defaultProps = {
  colorSetting: {
    textColor: COLORS.black,
    iconColor: COLORS.black,
    background: COLORS.white
  }
};
