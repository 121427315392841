import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { window, document } from '@constants';

import styles from './style.module.scss';

export const Modal = ({ children, open, onClose = () => null, isMenu }) => {
  const portalRoot = document?.querySelector('#portal');

  useEffect(() => {
    window?.addEventListener('click', onClose);
    return () => {
      window?.removeEventListener('click', onClose);
    };
  }, []);

  const viewPortHeight = window?.innerHeight;

  const content = useMemo(
    () =>
      !isMenu ? (
        <section className={styles.root} style={{ height: `${viewPortHeight}px` }}>
          <div className={styles.inner} onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </section>
      ) : (
        <div style={{ height: `${viewPortHeight}px` }} onClick={e => e.stopPropagation()}>
          {children}
        </div>
      ),
    [isMenu, children]
  );

  return portalRoot ? createPortal(open ? content : null, portalRoot) : null;
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isMenu: PropTypes.bool
};

Modal.defaultProps = {
  isMenu: false
};
