import React from 'react';
import PropTypes from 'prop-types';
import PropTypesAirbnb from 'airbnb-prop-types';
import styles from './style.module.scss';

export const Wrapper = ({ children, isHomePage }) => {
  return isHomePage ? (
    <div className={styles.root}>{children}</div>
  ) : (
    <div className={styles.container}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
};
Wrapper.propTypes = {
  children: PropTypesAirbnb.or([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  isHomePage: PropTypes.bool
};

Wrapper.defaultProps = {
  isHomePage: false
};
