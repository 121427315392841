import React from 'react';
import PropTypes from 'prop-types';

import { addClass } from '@utils';

import styles from './style.module.scss';

export const TextArea = ({ value, placeholder, touched, error, onChange, name }) => {
  return (
    <div className={styles.root}>
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        maxLength={168}
        className={addClass(styles.textArea, styles.textAreaError, touched && error)}
      ></textarea>
      {touched && error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};
