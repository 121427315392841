import React from 'react';
import PropTypes from 'prop-types';

import { addClass } from '@utils';

import styles from './style.module.scss';

export const Input = ({ name, value, type, placeholder, error, touched, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <input
        value={value}
        name={name}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        className={addClass(styles.input, styles.inputError, touched && error)}
      />

      {touched && error && <span className={styles.inputErrorMessage}>{error}</span>}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool
};

Input.defaultProps = {
  type: 'text'
};
