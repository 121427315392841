const win = typeof window !== 'undefined' ? window : undefined;
const doc = typeof document !== 'undefined' ? document : undefined;

export const COLORS = {
  white: 'white',
  black: '#000000',
  transparent: 'transparent'
};

export const screens = [
  {
    label: 'Home',
    color: COLORS.white,
    header: {
      textColor: COLORS.white,
      background: COLORS.black,
      iconColor: COLORS.white
    },
    smallHeader: {
      textColor: COLORS.white,
      background: COLORS.black,
      iconColor: COLORS.white
    },
    footer: {
      textColor: COLORS.white,
      withCopyright: false
    }
  },
  {
    label: 'Services',
    color: 'black',
    header: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    smallHeader: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    footer: {
      textColor: COLORS.black,
      withCopyright: false,
      exploreIcon: true
    }
  },
  {
    label: 'Our stack',
    color: 'black',
    header: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    smallHeader: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    footer: {
      textColor: COLORS.black,
      withCopyright: false
    }
  },
  {
    label: 'Why us',
    color: COLORS.white,
    header: {
      textColor: COLORS.black,
      background: COLORS.transparent,
      iconColor: COLORS.white
    },
    smallHeader: {
      textColor: COLORS.white,
      background: COLORS.black,
      iconColor: COLORS.white
    },
    footer: {
      textColor: COLORS.black,
      withCopyright: false
    }
  },
  {
    label: 'Clients',
    header: {
      textColor: COLORS.black,
      background: COLORS.transparent,
      iconColor: COLORS.white
    },
    smallHeader: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    footer: {
      withCopyright: false
    },
    color: 'white'
  },
  {
    label: 'Vacancies',
    header: {
      textColor: COLORS.black,
      background: COLORS.transparent,
      iconColor: COLORS.white
    },
    smallHeader: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
    footer: {
      withCopyright: false
    },
    color: 'white'
  },
  {
    label: 'Contact us',
    color: 'black',
    header: {
      textColor: COLORS.black,
      background: COLORS.black,
      iconColor: COLORS.black
    },
  }
];

export const links = [
  {
    label: 'Home',
    to: '/'
  },
  {
    label: 'Сareer',
    to: '/career/'
  },
  {
    label: 'Competence Center',
    to: '/competence_center/'
  }

  // TODO: return on the next release
  // {
  //   label: 'Cloud Partnerships',
  //   to: '/cloud'
  // },
  // {
  //   label: 'R&Ds for Partners',
  //   to: '/rd'
  // }
];

const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36
      },
      {
        color: '#333333'
      },
      {
        lightness: 40
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  }
];

export const animationEffects = {
  fadeIn: { from: { opacity: 0 }, to: { opacity: 1 } },

  fadeInDown: {
    from: {
      opacity: '0',
      transform: 'translateY(-200px)'
    },
    to: {
      opacity: '1',
      transform: 'translateY(0)'
    }
  },
  parallaxUp: {
    from: {
      marginTop: '0px'
    },
    to: {
      marginTop: '-360px'
    }
  },
  slideUp: {
    from: {
      transform: 'translateY(360px)'
    },
    to: {
      transform: 'translateY(0)'
    }
  },
  absoluteParallaxUp: {
    from: {
      bottom: '0px'
    },
    to: {
      bottom: '360px'
    }
  },
  appearTop: {
    from: {
      opacity: '0',
      transform: 'translateY(100%)'
    },
    to: {
      opacity: '1',
      transform: 'translateY(0)'
    }
  },
  appearLeft: {
    from: {
      opacity: '0',
      transform: 'translateX(-100%)'
    },
    to: {
      opacity: '1',
      transform: 'translateX(0)'
    }
  },
  appearRight: {
    from: {
      opacity: '0',
      transform: 'translateX(100%)'
    },
    to: {
      opacity: '1',
      transform: 'translateX(0)'
    }
  }
};

export { win as window, doc as document, mapStyles };
