import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '@components/Header';
// import { Footer } from '@components/Footer';

import { addClass } from '@utils';

import { useBreakpoints } from '@utils/hooks';
import styles from './style.module.scss';

export const PageContainer = ({
  children,
  pages,
  currentPage,
  // isMapShown,
  headerProps,
  resetPages,
  isSideMenuPage
}) => {
  const { isLg } = useBreakpoints();

  const isCustomScroll = !!pages;
  const { header } = isCustomScroll ? pages[currentPage] : {};
  // const { textColor, withCopyright, exploreIcon } = footer ?? {};
  const headerColorSetting = !isLg ? headerProps : header;

  return (
    <div>
      <Header colorSetting={headerColorSetting} resetPages={resetPages} />
      <div
        className={`${addClass(styles.content, styles.contentCustom, isCustomScroll)} ${addClass(
          styles.content,
          styles.contentPaddings,
          isSideMenuPage
        )}`}
      >
        {children}
      </div>

      {/*<Footer*/}
      {/*  color={textColor}*/}
      {/*  withCopyright={withCopyright}*/}
      {/*  exploreIcon={exploreIcon}*/}
      {/*  isCustomScroll={isCustomScroll}*/}
      {/*  isMapShown={isMapShown}*/}
      {/*/>*/}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      header: PropTypes.object,
      footer: PropTypes.object,
      label: PropTypes.string
    })
  ),
  currentPage: PropTypes.number,
  isMapShown: PropTypes.bool,
  resetPages: PropTypes.func,
  headerProps: PropTypes.shape({
    textColor: PropTypes.string,
    background: PropTypes.string,
    iconColor: PropTypes.string
  }).isRequired,
  isSideMenuPage: PropTypes.bool
};

PageContainer.defaultProps = {
  isDefaultScroll: true,
  isMapShown: false,
  isSideMenuPage: false
};
