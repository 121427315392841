import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import MenuClose from '@images/menu_close.svg';

import { Input } from '@components/Input';
import { TextArea } from '@components/TextArea';
import { Modal } from '@components/Modal';

import { addClass } from '@utils';
import { useBreakpoints } from '@utils/hooks';
import { manageMultipleListeners } from '@utils';

import styles from './style.module.scss';

import messageIcon from '@images/message.svg';

export const ContactModal = ({ withIcon = false }) => {
  const { isLg } = useBreakpoints();
  const formRef = useRef(null);

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting, setSubmitting } = useFormik({
    initialValues: {
      name: '',
      from: '',
      message: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      from: Yup.string()
        .email('Invalid email')
        .required('Required'),
      message: Yup.string().required('Required')
    }),

    onSubmit: (values, { resetForm }) => {
      fetch(GATSBY_APP_CONTACT_PAGE_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          from: values.from,
          name: values.name,
          message: values.message
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.sucess) {
            resetForm();
            setIsOpened(false);
            setBackendError(null);
            return;
          }
        })
        .catch(error => {
          setBackendError(error.message);
          setSubmitting(false);
        });
    }
  });

  const [isOpened, setIsOpened] = useState(false);
  const [backendError, setBackendError] = useState(null);

  const preventScroll = useCallback(event => {
    const isScrollInsideForm = formRef?.current?.contains(event.target);
    !isScrollInsideForm && event.preventDefault();
  }, []);

  useEffect(() => {
    const eventConfig = {
      element: document.body,
      events: ['touchmove', 'wheel'],
      callback: preventScroll,
      props: { passive: false }
    };

    if (isOpened) {
      manageMultipleListeners(eventConfig);
    } else {
      manageMultipleListeners({ ...eventConfig, type: 'remove' });
    }
  }, [isOpened]);

  // const openModal = event => {
  //   event?.stopPropagation();
  //   setIsOpened(!isOpened);
  // };

  const closeModal = () => setIsOpened(false);

  const handleKeyDown = event => {
    event.preventDefault();
  };

  return (
    <div>
      {!withIcon || isLg ? (
        <a
          href="mailto:contact@jssportainment.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.button}
        >
          Get In Touch
        </a>
      ) : (
        <div className={styles.btn}>
          <a
            href="mailto:contact@jssportainment.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={messageIcon}
              alt="message"
            />
          </a>
        </div>
      )}

      <Modal open={isOpened} onClose={closeModal}>
        <div id="contactForm" className={styles.form} ref={formRef}>
          <img alt="close menu" onClick={closeModal} src={MenuClose} className={styles.image} />
          <h6 className={styles.title}>Message, phone call, personal visit - whatever works for you works for us</h6>

          <form onSubmit={handleSubmit}>
            <div>
              <Input
                name={'name'}
                placeholder="Name"
                value={values.name}
                error={errors.name}
                touched={touched.name}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <Input
                name={'from'}
                placeholder="Email"
                value={values.from}
                error={errors.from}
                touched={touched.from}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />

              <TextArea
                placeholder={'Your message'}
                name="message"
                value={values.message}
                error={errors.message}
                touched={touched.message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button
              className={addClass(styles.submitBtn, styles.submitBtnDisabled, isSubmitting)}
              type="submit"
              disabled={isSubmitting}
            >
              Send
            </button>
          </form>
          <div className={styles.formError}>{backendError}</div>
        </div>
      </Modal>
    </div>
  );
};

ContactModal.propTypes = {
  withIcon: PropTypes.bool
};
